import React from "react";
import SkillItem from "./SkillItem";

const skillData = [
  {
    id: 1,
    title: "Html, Css, Scss",
    percentage: "95%",
  },
  {
    id: 2,
    title: "Docker",
    percentage: "90%",
  },
  {
    id: 3,
    title: "TailwindCSS (♥), Bootstrap",
    percentage: "95%",
  },
  {
    id: 4,
    title: "Wordpress (♥)",
    percentage: "100%",
  },
  {
    id: 5,
    title: "Javascript, ReactJS, Svelte, Vue, Nuxt ...",
    percentage: "56%",
  },
  {
    id: 6,
    title: "Python < Django (♥)",
    percentage: "70%",
  },
  {
    id: 7,
    title: "MongoDB, MySQL",
    percentage: "71%",
  },
  {
    id: 8,
    title: "GitLab, Github",
    percentage: "82%",
  },
];

const Skills = () => {
  return (
    <div className="py-4">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">Skills</h3>
          </div>
        </div>
        {skillData.map((skill, id) => (
          <SkillItem skill={skill} key={id} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
