import React from "react";
import {
  FaTelegramPlane,
  FaLinkedinIn,
  FaXing
} from "react-icons/fa";
import profile from "../../../images/Julian-Clamer.jpeg";

const socials = [
  {
    id: 1,
    icon: <FaTelegramPlane />,
    link: "https://t.me/przla",
  },
  {
    id: 2,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/julian-clamer/",
  },
  {
    id: 3,
    icon: <FaXing />,
    link: "https://www.xing.com/profile/Julian_Clamer/cv",
  }
];

const Sidebar = () => {
  return (
    <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
      <div className="w-24 h-24 rounded-md overflow-hidden mx-auto mb-5">
        <img src={profile} alt="shafiqhammad" className="w-full" />
      </div>
      <div className="text-center">
        <h1 className="text-xl text-gray-800 font-bold mb-1">Julian Clamer</h1>
        <p className="text-sm text-gray-400 mb-3">
          Freiberuflicher Softwarentwickler und Webdesigner
        </p>
        <a
          href="/kontakt"
          className="inline-block mb-3 rounded bg-green-250 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-green-250"
        >
          Kontakt
        </a>
        <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
        </ul>
      </div>
      <div className="text-start pt-4">
        <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
          Ziele
        </h3>
        <p className="text-gray-400 text font-light leading-relaxed">
        Ich bin ein motivierter Teamplayer und ambitionierter Webentwickler mit guten Kenntnissen in Webdesign, Serveradministration und Marketing. Mein Ziel ist es, mein Fachwissen in der Branche zu erweitern und meine Fähigkeiten zu nutzen, um Unternehmen und Privatpersonen bei der Gestaltung der eigenen, digitalen Präsenz sowie bei UI/UX-Themen zu unterstützen.
        </p>
      </div>
    </aside>
  );
};

export default Sidebar;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        className="w-8 h-8 bg-green-50 rounded text-green-250 flex items-center justify-center hover:text-white hover:bg-green-250"
      >
        {icon}
      </a>
    </li>
  );
};
