import React from "react";
import image1 from "../../../images/portfolio/nfk.jpg";
import image2 from "../../../images/portfolio/scf.jpg";
import image3 from "../../../images/portfolio/nfs.jpg";
import image4 from "../../../images/portfolio/ss.jpg";
import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: image1,
    title: "Nachfolgekontor GmbH",
    link: "https://nachfolgekontor.de",
    description:
      "Die Nachfolgekontor GmbH ist ein auf Unternehmensnachfolge spezialisiertes Unternehmen, das kleinen und mittleren Unternehmen (KMU) bei dem Verkauf ihres Unternehmens hilft. Ebenso sind sie Experten darin, einen geeigneten Käufer zu finden. Die Website wurde im Rahmen meiner Arbeit neu struktuiert und aufgebaut. Das Projekt ist mit Wordpress realisiert und befindet sich im ständigen Wandel.",
  },
  {
    id: 2,
    image: image2,
    title: "sonntag corporate finance GmbH",
    link: "https://sonntagcf.com",
    description:
      "Für einen verbesserten Workflow und weniger Frustration entschied sich sonntag corporate finance dafür, von TYPO3 zu Wordpress zu wechseln. Das bestehende Design wurde übernommen und entesprechend angepasst. Das online Marketing sowie die serverseitige Infrastruktur sollten ebenfalls einen frischen Anstrich bekommen.",
  },
  {
    id: 3,
    image: image3,
    title: "Nachfolgescout",
    link: "https://nachfolgescout.com",
    description:
      "Ein neues Projekt der Nachfolgekontor GmbH - umgesetzt mit Kirby CMS und Tailwind.",
  },
  {
    id: 4,
    image: image4,
    title: "Sprachsport",
    link: "https://sprachsport.com",
    description:
      "Sprachsport bringt Schülern über Bewegung eine Sprache bei. Sozusagen ein sportlicher Sprachkurs.",
  },
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
