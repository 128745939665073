import React from "react";
import { FaCode, FaHtml5, FaReact, FaPython, FaLaptopCode, FaChartLine, FaDocker } from "react-icons/fa";
import { SiTailwindcss } from "react-icons/si";
import ServiceItem from "./ServiceItem";

const serviceData = [
  {
    id: 1,
    icon: <FaCode />,
    title: "Web Design - UI/UX",
    description:
      "Je nach Wunsch designe ich das Projekt vorerst als Adobe XD Wireframe oder direkt in der Umgebung Ihrer Wahl (zum Beispiel Wordpress oder Tailwind). Über mehrere Iterationen hinweg wird so eine einheitliche Designsprache entwickelt.",
  },
  {
    id: 2,
    icon: <FaHtml5 />,
    title: "Webentwicklung",
    description:
      "Als mehrjähriger Freelancer im Bereich Frontend & Backend habe ich viel Erfahrung mit den gängigen Frameworks und Technologien. Dazu gehören HTML, CSS, PHP, MYSQL, JavaScript (& Frameworks), Linux-Systeme und viele weitere Bereiche.",
  },
  {
    id: 3,
    icon: <FaReact />,
    title: "Frontend Development",
    description:
      "Mittels Design-Frameworks wie Bootstrap und Tailwind und JavaScript-Frameworks wie React, HTMX und Angular gelingt immer ein gutes Frontend.",
  },
  {
    id: 4,
    icon: <FaLaptopCode />,
    title: "Backend Development",
    description:
      "Möchten Sie Ihre Daten selbst verwalten, so bietet sich Wordpress an - für Profis, die sich auskennen, lohnt sich der Einsatz von Frameworks wie Django oder Flask.",
  },
  {
    id: 5,
    icon: <SiTailwindcss />,
    title: "Tailwind CSS",
    description:
      "Tailwind macht CSS überhaupt erst rund.",
  },
  {
    id: 6,
    icon: <FaPython />,
    title: "Django",
    description:
      "Django ist das perfekte allrounder Backend.",
  },
  {
    id: 7,
    icon: <FaChartLine />,
    title: "Online Marketing",
    description:
      "Suchmaschinenoptimierung (SEO) und das Schalten von Anzeigen gehören zu meinen täglichen Aufgaben. Für die Recherche nutze ich Tools wie Ahrefs, Seobility, Ryte und weitere im Bereich der Keyword- und Backlink-Analyse.",
  },
  {
    id: 8,
    icon: <FaDocker />,
    title: "Administration & Docker",
    description:
      "Ich administriere auch Ihren Server, kümmere mich um die dauerhafte Erreichbarkeit Ihrer E-Mails und orchestriere Ihre Docker-Container für einen reibungslosen Ablauf. CI/CD gehören selbstverständlich dazu.",
  },
];

const Service = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {serviceData.map((service, id) => (
          <ServiceItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Service;
