import React from "react";
import Skills from "./Skills/Skills";

const About = () => {
  return (
    <section className="py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">Hallo,</h3>
            <p className="text-sm text-gray-400 leading-6 mb-3">
            ich bin Julian Clamer, Webentwickler, Designer, Content Mananger, Administrator, SEO-Spezialist und Medieninformatiker aus Freiburg. Sowohl beruflich als auch privat beschäftige ich mit Themen rund um das Web. Sowohl vor als auch hinter den Kulissen. Dadurch habe ich viel Erfahrung sammeln können mit Technologien wie HTML, CSS, JS, PHP, Python/Django, Java, Wordpress, Tailwind, Git, Docker und vielen weiteren.
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              Seit 2017 bin ich freiberuflich unterwegs und arbeite an einer Menge sehr unterschiedlicher Projekte.
            </p>
          </div>
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default About;
