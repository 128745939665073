import React from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from "react-router-dom";
import About from "../../pages/About/About";
//import Blog from "../../pages/Blog/Blog";
import Contact from "../../pages/Contact/Contact";
import Portfolio from "../../pages/Portfolio/Portfolio";
import Service from "../../pages/Service/Service";

const navbarData = [
  {
    id: 1,
    title: "Über mich >_",
    to: "/ueber",
  },
  {
    id: 2,
    title: "Services </>",
    to: "/services",
  },
  {
    id: 3,
    title: "Projekte",
    to: "/projekte",
  },
  // {
  //   id: 4,
  //   title: "Blog",
  //   to: "/blog",
  // },
  {
    id: 5,
    title: "Kontakt",
    to: "/kontakt",
  },
];

const Navbar = () => {
  return (
    <Router>
      <nav className="md:mx-8 mb-3 px-6 py-2 z-10 sticky top-0 bg-white shadow rounded">
        <ul className="flex flex-wrap">
          {navbarData.map((el, id) => (
            <LinkItem el={el} key={id} />
          ))}
        </ul>
      </nav>

      <Switch>
        <Route exact path="/">
          <About />
        </Route>
        <Route path="/services">
          <Service />
        </Route>
        <Route path="/projekte">
          <Portfolio />
        </Route>
        {/* <Route path="/blog">
          <Blog />
        </Route> */}
        <Route path="/kontakt">
          <Contact />
        </Route>
        <Route path="/ueber">
          <About />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navbar;

const LinkItem = (props) => {
  const { title, to } = props.el;
  return (
    <li className="m-3 lg:mx-5">
      <NavLink
        to={to}
        activeClassName="text-green-250"
        className="text-gray-800 text-medium hover:text-green-250"
      >
        {title}
      </NavLink>
    </li>
  );
};
