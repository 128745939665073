import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        <form className="p-8 md:mx-4 bg-white rounded-md shadow-md w-full text-center">
          <div className="m-3">
            <h3 className="text-2xl text-gray-800 font-bold mb-3">
              Noch Fragen?
            </h3>
          </div>
          <div className="m-3">
            <h3 className="text-xl text-gray-200 font-medium mb-6">
              Lass uns ins Gespräch kommen.
            </h3>
          </div>
          hi(at)julian-clamer.de
          <ToastContainer />
        </form>
      </div>
    </section>
  );
};

export default Contact;
